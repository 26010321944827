import { enUS } from 'date-fns/locale';
import { setDefaultLocale, registerLocale } from 'react-datepicker';
import localeJson from '../locales/locales_en.json';

localeJson.dateFns = enUS;
localeJson.code = 'en-US';
window.locales = localeJson;

// Default needs to be registered with the hash, not the identifier:
// see https://github.com/Hacker0x01/react-datepicker/issues/1914
setDefaultLocale(localeJson.dateFns);
registerLocale(localeJson.code, localeJson.dateFns);
